<template>
  <div>
    <vx-card>
      <div class="flex">
        <vs-input
          class="my-2"
          label-placeholder="search key"
          @keyup.enter="getAllCloud()"
          v-model="search"
        ></vs-input>
        <vs-button
          class="my-2"
          size="large"
          @click="getAllCloud()"
          icon="search"
        >
        </vs-button>
      </div>
      <vs-pagination :total="countPage" v-model="pageNumber"></vs-pagination>
      <vs-table
        @dblSelection="doubleSelection"
        v-model="selectedhaiocloud"
        :data="userList"
      >
        <template slot="header"> </template>
        <template slot="thead">
          <vs-th> title </vs-th>
          <vs-th> vps name </vs-th>
          <vs-th> status </vs-th>
          <vs-th> cpu (core)</vs-th>
          <vs-th> ram (GB)</vs-th>
          <vs-th> hard(GB) </vs-th>
          <vs-th> node ip </vs-th>
          <vs-th> public ip </vs-th>
          <vs-th> ipid </vs-th>
          <vs-th> node name </vs-th>
          <vs-th> image </vs-th>
          <vs-th> created at </vs-th>
          <vs-th> User Info </vs-th>
          <vs-th> operation </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].subject">
              {{ data[indextr].title }}
            </vs-td>
            <vs-td :data="data[indextr].user_fullname">
              {{ tr.vps_name }}
            </vs-td>
            <vs-td :data="data[indextr].status">{{
              data[indextr].instance_status
            }}</vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].cpu }}
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].ram }}
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].storage }}
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].nodeIP }}
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].public_ip }}
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].ipid }}
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].node }}
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              {{ data[indextr].image_title }} ** {{ tr.image_version }}
            </vs-td>
            <vs-td :data="data[indextr].updated_at">
              {{ data[indextr].created_at }}</vs-td
            >
            <vs-td :data="data[indextr].updated_at">
              <vs-button
                size="small"
                icon="account_box"
                :to="`/user?id=${data[indextr].user_id}`"
              ></vs-button>
            </vs-td>
            <vs-td :data="data[indextr].category_name">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <!-- rebbot and delete -->
                <vs-button
                  size="small"
                  class="btn-drop"
                  type="filled"
                  icon="expand_more"
                ></vs-button>

                <vs-dropdown-menu>
                  <vs-dropdown-item>
                    <vs-button
                      @click="deletePrompt(tr.id)"
                      line-origin="left"
                      color="danger"
                      type="gradient"
                      >Delete</vs-button
                    >
                  </vs-dropdown-item>
                  <vs-dropdown-item>
                    <vs-button
                      @click="rebootPrompt(tr.id)"
                      line-origin="left"
                      color="warning"
                      type="gradient"
                      >Rebbot</vs-button
                    >
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        class="my-3"
        :total="countPage"
        v-model="pageNumber"
      ></vs-pagination>
      <!-- <p> {{ selectedhaiocloud }} </p> -->
      <vue-json-pretty :data="selectedhaiocloud"> </vue-json-pretty>
    </vx-card>
    <!-- delete prompt -->
    <vs-prompt
      @cancel="val = ''"
      @accept="deleteCloud"
      :active.sync="deleteActive"
    >
      <div class="con-exemple-prompt">
        مطمعنی میخوای <span class="text-warning"> حذفش</span> کنی خداوکیلی؟
        فکراتو کردی؟
      </div>
    </vs-prompt>

    <!-- Reboot Prompt -->
    <vs-prompt
      @cancel="val = ''"
      @accept="rebootCloud"
      :active.sync="rebootActive"
    >
      <div class="con-exemple-prompt">
        مطمعنی میخوای <span class="text-danger"> ریبوتش</span> کنی خداوکیلی؟
        فکراتو کردی؟
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  data: () => ({
    rebootActive: false,
    deleteActive: false,
    search: "",
    userId: "",
    countPage: 0,
    pageNumber: 1,
    selectedhaiocloud: "",
    userList: [],
    selectedID: "",
  }),
  watch: {
    pageNumber() {
      this.getAllCloud();
    },
  },
  methods: {
    rebootPrompt(id) {
      this.selectedID = id;
      this.rebootActive = true;
    },
    deletePrompt(id) {
      this.selectedID = id;
      this.deleteActive = true;
    },
    rebootCloud() {
      this.$http
        .post("/admin/haiocloud/instance/reboot", { id: this.selectedID })
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    deleteCloud() {
      this.$http
        .delete(`admin/haiocloud/instance?id=${this.selectedID}`)
        .then((res) => {
          this.getAllCloud();
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err });
        })
        .finally(() => {
          this.getAllCloud();
        });
    },

    getAllCloud() {
      this.$vs.loading();
      this.$http
        .get(`/admin/haioclouds/?search=${this.search}&page=${this.pageNumber}`)
        .then((res) => {
          this.$vs.loading.close();
          const p = res.data.params;
          this.countPage = p.last_page;
          this.userList = p.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    doubleSelection() {
      this.$router.push(`/user?id=${this.selectedhaiocloud.user_id}`);
    },
  },
  created() {
    this.userId = this.$route.params.id;
    this.getAllCloud();
  },
};
</script>
